<template>
    <v-card tile flat class="px-5" @click="gotoProduct">
    <v-img
      height="260"
      contain
      :src="product.image || 'https://dummyimage.com/260x260'"
    ></v-img>
      <v-row>
          <v-card-title class="subtitle-2">
              <div>
                <div style="text-transform: uppercase; letter-spacing: .2em" class="font-weight-thin grey--text">{{ product.category.name }}</div>
                <div class="title font-weight-bold">{{ product.name }}</div>
                <div><v-icon x-small>mdi-tag</v-icon> {{ product.price }}</div>
              </div>  
          </v-card-title>
      </v-row>
    </v-card>
</template>

<script>
    export default {
        name: 'ProductCard',
        props: ['product'],
        methods: {
            gotoProduct() {
              this.$router.push(`/product/${this.product.id}`)
            }
        }
    }
</script>
