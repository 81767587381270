<template>
  <v-dialog v-model="dialog" max-width="290">
    <template v-slot:activator="{ on }">
      <text-field
          class="my-5"
          readonly
          :label="label"
          prepend-inner-icon="mdi-calendar"
          placeholder="Click to select"
          v-on="on"
          clearable
          @click:clear="$emit('click:clear')"
          :error-messages="timeErrors"
          :value="asDatetime(value)"
      ></text-field>
    </template>
    <v-card flat>
      <v-date-picker
          v-model="date"
          :readonly="readonly"
          @input="bump"
          :allowed-dates="allowedDates"
      ></v-date-picker>
      <v-divider v-if="store && !store.disableTimePickers"></v-divider>
      <v-card-text  v-if="store && !store.disableTimePickers">
        <v-subheader>Select time</v-subheader>
        <time-picker
            v-model="time"
            label="Select time"
            :readonly="readonly"
            :opening-hours="openingHours"
            :current-date="date"
            hide-details
            :min-time="minTime"
            :max-time="maxTime"
            :timeErrors="timeErrors"
        />
      </v-card-text>
      <v-card-actions v-if="store.disableTimePickers">
        <v-spacer/>
        <btn
          color="primary"
          @click="dialog=false"
        >
          OK
        </btn>
      </v-card-actions>

    </v-card>
  </v-dialog>


</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'DateTimePicker',
  props: ['allowedDates', 'value', 'openingHours', 'readonly', 'label', 'timeErrors', 'minTime', 'maxTime'],
  data: () => ({
    dialog: false,
    stepper: 1
  }),
  methods: {
    asDatetime (value) {
      if (value.date && value.time) {
        if (this.store && this.store.disableTimePickers) {
          return value.date
        }
        return moment(`${value.date}T${value.time}`).format('lll')
      }
      if (value.date) {
        return value.date
      }
      return ''
    },
    bump (value) {
      this.date = value
      this.stepper = 2
    }
  },
  watch: {
    dialog (value) {
      if (value) this.stepper = 1
    },
    time (value) {
      if (value) this.dialog = false
    }
  },
  computed: {
    ...mapState(['store']),
    date: {
      get () {
        return this.value.date
      },
      set (value) {
        this.$emit('input', { date: value, time: this.time })
      }
    },
    time: {
      get () {
        return this.value.time
      },
      set (value) {
        this.$emit('input', { time: value, date: this.date })
      }
    }
  }
}
</script>

<style>
.datetime > .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
  padding: 0 4px;
}
.theme--light.v-sheet--outlined {
  border-color: rgba(0, 0, 0, 0.87);

}

</style>