<template>
    <v-dialog
            v-model="dialog"
            :width="width"
            v-bind="$attrs"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            :persistent="persistent"
    >
        <template v-slot:activator="slotProps">
          <slot name="button" v-bind="slotProps">
            <v-icon @click="openDialog" v-if="action" small>{{btnText}}</v-icon>
            <btn v-on="slotProps.on" v-else :disabled="disabled" color="primary">
                {{ btnText }}
            </btn>
            </slot>
        </template>

        <v-card style="width: 100vw !important">
          <v-toolbar flat dense v-show="$vuetify.breakpoint.smAndDown">
            <v-spacer/>
            <v-btn icon flat @click="dialog = false">
              <v-icon>fa-times-circle</v-icon>
            </v-btn>
          </v-toolbar>
          <slot name="header" v-bind="$props">
            <nice-header>
                {{title}}
                <template v-slot:extra>
                    <v-spacer></v-spacer>
                    <v-btn v-if="persistent" icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </template>
            </nice-header>
          </slot>

            <v-card-text v-if="subheader" class="body-2 justify-center text-center pb-0 px-0" v-text="subheader"></v-card-text>
            <slot></slot>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            width: {
                type: String,
                default: '500'
            },
            value: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Modal'
            },
            btnText: {
                type: String,
                default: 'Click'
            },
            disabled: Boolean,
            persistent: Boolean,
            subheader: String,
            action: Boolean,
            fullscreen: Boolean
        },
        methods: {
            openDialog () {
                if (!this.disabled) {
                    this.dialog = true
                }
            }
        },
        computed: {
            dialog: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            }
        }
    }
</script>
