<template>
  <v-img :src="category.image" contain :max-height="small ? '300' : '800'">
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="auto" class="white pt-2">
          <router-link :to="`/products?category=${category.name}`" style="text-decoration: none; !important">
            <div class="text-center"><a
                :class="`text-center black--text display-${$vuetify.breakpoint.mdAndUp ? small ? '1' : '2' : '1'} font-weight-semibold pa-${$vuetify.breakpoint.mdAndUp ? '4' : '2'}`"
                v-text="category.name"
              />
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
export default {
  props: ['category', 'small']
}
</script>