<template>
  <v-footer v-if="store" clipped-left>
    <v-row no-gutters>
      <v-col cols="12" md="4" :class="$vuetify.breakpoint.mdAndUp ? 'px-10 my-10' : 'mt-5'">
          <v-list class="opacity-0 transparent" subheader>
            <v-subheader v-text="store.name" class="display-1"/>
            <v-list-item v-if="store.address">
              <v-list-item-icon class="mx-2"><v-icon v-text="`fa-map-pin`"/></v-list-item-icon>
              <v-list-item-content><v-list-item-title v-text="store.address"/></v-list-item-content>
            </v-list-item>
            <v-list-item v-if="store.email">
              <v-list-item-icon class="mx-2"><v-icon v-text="`fa-envelope-open`"/></v-list-item-icon>
              <v-list-item-content><v-list-item-title v-text="store.email"/></v-list-item-content>
            </v-list-item>
            <v-list-item v-if="store.phone">
              <v-list-item-icon class="mx-2"><v-icon v-text="`fa-phone`"/></v-list-item-icon>
              <v-list-item-content><v-list-item-title v-text="store.phone"/></v-list-item-content>
            </v-list-item>
          </v-list>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="px-10 my-10" v-show="$vuetify.breakpoint.smAndUp">
        <h4 class="primary-title" v-text="`Categories`"/>
        <router-link
            v-for="(cat, i) in categories"
            :key="i"
            :to="`/products?category=${cat}`"
            class="black--text"
            style="text-decoration: none"
        >
          <div>{{cat}}</div>
        </router-link>
      </v-col>
      <v-col cols="12" md="4" sm="6" class="px-10 my-10">
        <h4 class="primary-title" v-text="`Information`"/>
        <router-link to="/about" class="black--text" style="text-decoration: none">
          <div>About</div>
        </router-link>
        <router-link to="/contact" class="black--text" style="text-decoration: none">
          <div>Contact us</div>
        </router-link>
        <router-link to="/legal" class="black--text" style="text-decoration: none">
          <div>Legal notices</div>
        </router-link>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { categories } from '@/graphql'
export default {
  props: ['store',],
  apollo: { categories }
}
</script>