<template>
  <v-btn
    :outlined="inputStyles.outlined"
    :dense="inputStyles.dense"
    :rounded="inputStyles.rounded"
    :loading="loading"
    :color="color"
    :link="link"
    :to="to"
    :text="text"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  >
    <slot></slot>
  </v-btn>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
  name: 'Btn',
  props: ['disabled', 'loading', 'color', 'link', 'to', 'text', 'on'],
  computed: mapState(['inputStyles'])
}
</script>
