import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

  const routes = [
  {
    path: '/basket',
    name: 'Basket',
    component: () => import('../views/Basket.vue'),
    props: route => ({ store: route.params.store })
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: route => ({ store: route.params.store })
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    props: route => ({ store: route.params.store })
  },{
    path: '/contact',
    name: 'Contact us',
    component: () => import('../views/Contact.vue'),
    props: route => ({ store: route.params.store })
  },
  {
    path: '/product/:id',
    name: 'Product page',
    component: () => import('../views/Product.vue'),
    props: route => ({ store: route.params.store })
  },
  {
    path: '/orders/:accessToken',
    name: 'Order',
    component: () => import('../views/Order.vue'),
    props: route => ({ store: route.params.store })
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products.vue'),
    props: route => ({ store: route.params.store })
  },
  {
    path: '/legal',
    name: 'Legal documents',
    component: () => import('../views/Legal.vue'),
    props: route => ({ store: route.params.store })
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router
