<template>
  <v-row v-show="categories.length > 2" :class="$vuetify.breakpoint.mdAndUp ? 'pa-10' : 'pa-0'" align="center" justify="center">
    <v-col cols="12" md="8">
      <category-card :category="categories[0]"/>
    </v-col>
    <v-col cols="12" md="4" class="pa-0">
      <v-col cols="12">
        <category-card :category="categories[1]"/>
      </v-col>
      <v-col cols="12">
        <category-card :category="categories[2]"/>
      </v-col>
    </v-col>
    <v-col v-for="(category, i) in extraCategories" :key="i" cols="12" md="3">
      <category-card :category="category" :small="true"/>
    </v-col>
  </v-row>
</template>

<script>
import { categoryImages as query } from "@/graphql";
import CategoryCard from "@/components/cards/CategoryCard";

export default {
  components: {CategoryCard},
  apollo: {
    categoryImages: {
      query,
      update: ({ store }) => store.categories
    }
  },
  computed: {
    categories () {
      if (this.categoryImages) {
        return this.categoryImages.filter(c => !!c.image)
      }
      return []
    },
    extraCategories () {
      if (this.categories) {
        return this.categories.slice(3)
      }
      return []
    }
  },
  methods: {
    getCols (index) {
      if (index === 0) return 8
      if (index > 4) return 6
      return 4
    }
  }
}
</script>