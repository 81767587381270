export const addItem = require('./core/addItem.gql')
export const availability = require('./core/availability.gql')
export const availableDates = require('./core/availableDates.gql')
export const basket = require('./core/basket.gql')
export const brands = require('./core/brands.gql')
export const categories = require('./core/categories.gql')
export const createEnquiry = require('./core/createEnquiry.gql')
export const genders = require('./core/genders.gql')
export const getCheckout = require('./core/getCheckout.gql')
export const getManualOrderCheckout = require('./core/getManualOrderCheckout.gql')
export const order = require('./core/order.gql')
export const orderLogin = require('./core/orderLogin.gql')
export const placeOrder = require('./core/placeOrder.gql')
export const products = require('./core/products.gql')
export const publishableKey = require('./core/publishableKey.gql')
export const removeItem = require('./core/removeItem.gql')
export const sizes = require('./core/sizes.gql')
export const store = require('./core/store.gql')
export const storeProduct= require('./core/storeProduct.gql')
export const theme = require('./core/theme.gql')
export const createReview = require('./core/createReview.gql')
export const bookingWindows = require('./core/bookingWindows.gql')
export const distanceDiff = require('./core/distanceDiff.gql')
export const addressSearch = require('./core/addressSearch.gql')
export const sessionId = require('./core/sessionId.gql')
export const setBasketAddress = require('./core/setBasketAddress.gql')
export const productSearch = require('./core/productSearch.gql')
export const categoryImages = require('./core/categoryImages.gql')

export default {
    addItem,
    availability,
    availableDates,
    basket,
    brands,
    categories,
    createEnquiry,
    genders,
    getCheckout,
    getManualOrderCheckout,
    order,
    orderLogin,
    placeOrder,
    products,
    publishableKey,
    removeItem,
    sizes,
    store,
    storeProduct,
    theme,
    createReview,
    bookingWindows,
    distanceDiff,
    addressSearch,
    sessionId,
    setBasketAddress,
    productSearch,
    categoryImages
}