<template>
  <v-select
      v-bind="{ ...$attrs, ...$props }"
      dense
      flat
      solo
      no-data-text="Please choose a date first"
      :items="times"
      placeholder="HH:MM"
      v-model="time"
  />
</template>

<script>
import moment from 'moment'

export default {
  name: 'TimePicker',
  props: ['value', 'label', 'dense', 'openingHours', 'currentDate', 'minTime', 'maxTime'],
  computed: {
    times() {
      const times = []
      if (this.currentDate) {
        let day = moment(this.currentDate).weekday() - 1
        if (day === -1) {
          day = 6
        }
        const oh = this.openingHours.find(oh => oh.day === day)
        if (oh) {
          const start = moment(oh.openingTime, 'kk:mm:ss')
          const end = moment(oh.closingTime, 'kk:mm:ss')

          for (let m = start; m.isSameOrBefore(end); m.add(1, 'hours')) {
            let include = true
            if (this.minTime) {
              if (!m.isSameOrAfter(this.minTime)) include = false
            }
            if (this.maxTime) {
              if (!m.isSameOrBefore(this.maxTime)) include = false
            }
            if (include) times.push(m.format('kk:mm'));

          }
        }

      }
      return times
    },
    time: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

  }
}
</script>
