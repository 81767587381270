<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <btn v-on="on" color="accent" class="no-transform">Review this order</btn>
    </template>
    <v-card flat>
      <nice-header>
        Review your order
      </nice-header>
      <v-card-text>
        <v-form v-model="valid" ref="form">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-subheader>Your rating:</v-subheader>
            <v-rating class="pb-3" v-model="form.starRating" label="Your rating"></v-rating>
          </v-col>
          <v-col cols="12">
            <v-textarea outlined auto-grow v-model="form.text" label="Your comments"></v-textarea>
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <btn class="no-transform" @click="submit" :loading="loading" color="accent">Submit</btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createReview } from "@/graphql";

export default {
  name: 'ReviewDialog',
  props: ['order'],
  data: () => ({
    dialog: false,
    valid: null,
    form: {
      text: null,
      starRating: 5,
    },
    loading: false
  }),
  methods: {
    submit() {
      this.loading = true
      this.$apollo.mutate({
        mutation: createReview,
        variables: {
          order: this.order.hashId,
          ...this.form
        }
      }).catch((error) => {
        this.error = true
        this.errorMessage = error.message.replace('GraphQL error:', '')
        this.loading = false
      }).then((result) => {
        if (result) {
          this.$refs.form.reset()
          this.$emit('submit')
          this.dialog = false
          this.loading = false
        }
      })
    }
  }
}
</script>


<style scoped>
.no-transform {
  text-transform: none;
  font-size: 16px;
}
</style>

