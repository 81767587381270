<template>
  <v-list v-if="store">
       <date-time-picker-set
        :store="store"
      />
      <v-subheader>Hire type</v-subheader>
      <span>
          <v-list-item v-for="(type, i) in deliveryTypes" :key="`deliv-${i}`">
              <v-list-item-action>
                  <v-checkbox
                      :value="type.value"
                      :disabled="basket && basket.lineItems.length > 0"
                      v-model="filters.hireType"
                  />
              </v-list-item-action>
              <v-list-item-content>
                  <v-list-item-title
                      :class="basket && basket.lineItems.length > 0 ? 'grey--text' : null">{{
                      type.text
                    }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          </span>
      <span v-if="categories && categories.length > 0">
          <v-subheader>
              Product category
          </v-subheader>
          <v-list-item v-for="(category, i) in categories" :key="`cat-${i}`">
              <v-list-item-action>
                  <v-checkbox :value="category" v-model="filters.categories"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                  <v-list-item-title>{{ category }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          </span>

      <span v-if="store.labelOneChoices && store.labelOneChoices.length > 0">
          <v-subheader>{{ store.labelOne }}</v-subheader>
          <v-list-item v-for="(size, i) in store.labelOneChoices" :key="`size-${i}`">
              <v-list-item-action>
                  <v-checkbox :value="size.value" v-model="filters.sizes"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                  <v-list-item-title>{{ size.value }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          </span>

      <span v-if="store.labelTwoChoices && store.labelTwoChoices.length > 0">
              <v-subheader>{{ store.labelTwo }}</v-subheader>
              <v-list-item v-for="(gender, i) in store.labelTwoChoices" :key="`gen-${i}`">
                  <v-list-item-action>
                      <v-checkbox :value="gender.value" v-model="filters.genders"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title>{{ gender.value }}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
          </span>

      <span v-if="store.labelThreeChoices && store.labelThreeChoices.length > 0">
        <v-subheader>{{ store.labelThree }}</v-subheader>
        <v-list-item v-for="(brand, i) in store.labelThreeChoices" :key="`brand-${i}`">
          <v-list-item-action>
            <v-checkbox :value="brand.value" v-model="filters.brands"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ brand.value }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </span>
  </v-list>
</template>

<script>
import {categories, store, availableDates} from '@/graphql'
import DateTimePickerSet from "@/components/DateTimePickerSet";
import { mapState } from 'vuex'

export default {
  name: 'Filters',
  components: {DateTimePickerSet},
  props: ['value', 'showDeliveryTypes', 'hireType', 'show'],

  data: () => ({
    sidebar: true,
    deliveryTypes: [
      {text: 'Collection from store', value: 'collection'},
      {text: 'Delivery', value: 'delivery'},
    ]
  }),
  apollo: {categories, store, availableDates},
  watch: {
    hireType(value) {
      if (value) {
        this.filters.hireType = value
      }
    },
    '$route.query': {
      handler() {
        this.filters.categories = [this.$route.query.category]
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.query.category) {
      this.filters.categories = [this.$route.query.category]
    }

  },
  computed: {
    ...mapState(['basket']),

    filters: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  },
}
</script>
