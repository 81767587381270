<template>
  <v-app-bar height="45px" dense flat class="secondary px-lg-16" :dark="getDarkMode('secondary')" v-show="show">
    <v-toolbar-title v-if="store">
      
      <v-btn id="twitter" icon x-small class="px-3" link :href="store.twitter" v-if="store.twitter">
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
    
      <v-btn id="facebook" icon x-small class="px-3" link :href="store.facebook" v-if="store.facebook">
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn id="instagram" icon x-small class="px-3" link :href="store.instagram" v-if="store.instagram">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
    </v-toolbar-title>
      
    <v-spacer/>
    <span class="text-right" v-if="store.phone">
      Call us now on <span class="font-weight-bold">{{ store.phone }}</span>
    </span>
      
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState(['store']),
    ...mapGetters(['getDarkMode']),
    show () {
      if (this.$vuetify.breakpoint.xs) return false;
      if (this.store) {
        if (!this.store.facebook && !this.store.twitter && !this.store.instagram && !this.store.phone) {
          return false
        } else {
          return true
        }
      }
      return false
    }
  }
}
</script>
