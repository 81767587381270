<template>
  <v-dialog v-model="dialog" max-width="400">
    <template v-slot:activator="{ on }">
      <v-row justify="center" align="center">
          <v-rating
            half-increments
            readonly
            :value="average"
          />
        <span class="caption mr-2">
          ({{ average }}/5)
        </span>
        <a v-on="on" v-if="reviews.length > 0">Show all reviews</a>
      </v-row>

    </template>
    <v-card flat>
      <nice-header>Ratings</nice-header>
      <v-list three-line>
        <template v-for="(review, i) in reviews">
          <v-list-item :key="i">
            <v-list-item-content>
              <v-list-item-subtitle :class="!!review.text ? null : 'grey--text'">{{review.text || 'No feedback provided'}}</v-list-item-subtitle>
              <v-list-item-title>
                <v-rating readonly :value="review.starRating"></v-rating>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="review.text"/>
        </template>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RatingsDialog',
  props: ['reviews'],
  data: () => ({
    dialog: false
  }),
  computed: {
    average () {
      if (this.reviews) {
        const ratings = this.reviews.map((rev) => rev.starRating).reduce((a,b) => a + b)
        return (ratings / this.reviews.length).toFixed(1)
      }
      return null
    }
  }
}
</script>