<template>
  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
    <v-card flat @click="$emit('select')">
      <v-img
        height="200"
        :src="product.image || 'https://dummyimage.com/200x250/949494/000000&text=No+image+available'"
      ></v-img>
      <v-card-title class="justify-center">
        <div>
          <h3 class="headline accent--text text--accent-2 text-center" v-text="product.name"/>
          <p class="body-1 text-center" v-text="product.price"/>
        </div>
      </v-card-title>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ['product']
}
</script>