<template>
    <div>
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <v-btn :loading="loading || $apollo.loading" icon small color="error" @click="deleteItem">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {removeItem} from '@/graphql'
    export default {
        name: 'DeleteLineItem',
        props: ['itemId'],
        data: () => ({
            loading: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            deleteItem() {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: removeItem,
                    variables: {
                        itemId: this.itemId
                    }
                }).then(() => {
                    this.$emit('delete');
                    this.loading = false
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message;
                    this.loading = false
                })
            }

        }
    }
</script>
