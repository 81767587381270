<template>
  <div>
    <v-list-item>
      <date-time-picker
          v-model="start"
          label="Start date/time"
          :readonly="basket && basket.lineItems.length > 0"
          :allowed-dates="allowedDates"
          @click:clear="start = { date: null, time: null }"
          :opening-hours="store.openingHours"
          :max-time="maxStartTime"
          :timeErrors="startTimeErrors"
      />
    </v-list-item>
    <v-list-item>
      <date-time-picker
          v-model="end"
          label="End date/time"
          :readonly="basket && basket.lineItems.length > 0"
          :allowed-dates="allowedEndDates"
          @click:clear="end = { date: null, time: null }"
          :opening-hours="store.openingHours"
          :min-time="minEndTime"
          :timeErrors="endTimeErrors"
      />
    </v-list-item>
    </div>
</template>


<script>
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import {availableDates} from '@/graphql'

export default {
  props: ['store'],
  apollo: {availableDates},
  computed: {
    ...mapState(['startDatetime', 'endDatetime', 'basket']),
    startTimeErrors() {
      return this.timeErrors('Start time must be before end time')
    },
    endTimeErrors() {
      return this.timeErrors('End time must be after start time')
    },
    start: {
      get() {
        return this.startDatetime
      },
      set(value) {
        this.setStartDatetime(value)
      }
    },
    end: {
      get() {
        return this.endDatetime
      },
      set(value) {
        this.setEndDatetime(value)
      }
    },
    maxStartTime() {
      const {date: startDate} = this.start;
      const {date: endDate, time: endTime} = this.end;
      if (endTime) {
        if (startDate === endDate) {
          return moment(endTime, 'hh:mm').subtract(1, 'hours')
        }
      }
      return null
    },
    minEndTime() {
      const {time: startTime, date: startDate} = this.start;
      const {date: endDate} = this.end;
      if (startTime) {
        if (startDate === endDate) {
          return moment(startTime, 'hh:mm').add(1, 'hours')
        }
      }
      return null
    },
  },
  methods: {
    ...mapMutations(['setStartDatetime', 'setEndDatetime']),
    timeErrors(msg) {
      let errors = []
      const {date: startDate, time: startTime} = this.start
      const {date: endDate, time: endTime} = this.end
      if (startDate === endDate) {
        if (moment(startTime, 'hh:mm').isSameOrAfter(moment(endTime, 'hh:mm'))) {
          errors.push(msg)
        }
      }
      return errors.join(',')
    },
    allowedDates(val) {
      if (this.availableDates) {
        if (this.availableDates.indexOf(val) > -1) {
          const {date: endDate} = this.end
          if (endDate) {
            return moment(val).isSameOrBefore(endDate)
          }
          return true
        }
      }
      return false;
    },
    allowedEndDates(val) {
      if (this.availableDates) {
        if (this.availableDates.indexOf(val) > -1) {
          const {date: startDate} = this.start
          if (startDate) {
            return moment(val).isSameOrAfter(startDate)
          }
          return true
        }
      }
      return false;
    }
  }
}
</script>