import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'


Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    store: null,
    basket: null,
    products: [],
    startDatetime: {
      date: null,
      time: null
    },
    endDatetime: {
      date: null,
      time: null
    },
    theme: {},
    styles: null
  },
  mutations: {
    setStore (state, { store }) {
      state.store = store
    },
    setBasket (state, { basket }) {
      state.basket = basket
    },
    setProducts (state, { products }) {
      state.products = products
    },
    setStartDatetime(state, { date, time }) {
      state.startDatetime = { date, time }
    },
    setEndDatetime(state, { date, time }) {
      state.endDatetime = { date, time }
    },
    setTheme (state, theme) {
      state.theme = theme
    },
    setInputStles (state, styles) {
      state.inputStyles = styles
    }
  },
  getters: {
    getStartDatetime: state => {
      const { date, time } = state.startDatetime
      if (date && time) {
        return `${date}T${time}`
      }
      if (date) {
        const { store: { openingHours } } = state;
        let day = moment(date).weekday() - 1
        if (day === -1) {
          day = 6
        }
        const calculatedTime = openingHours[day].openingTime
        return `${date}T${calculatedTime}`
      }
      return null
    },
    getEndDatetime: state => {
      const { date, time } = state.endDatetime
      if (date && time) {
        return `${date}T${time}`
      }
      if (date) {
        const { store: { openingHours } } = state;
        let day = moment(date).weekday() - 1
        if (day === -1) {
          day = 6
        }
        const calculatedTime = openingHours[day].closingTime
        return `${date}T${calculatedTime}`
      }
      return null
    },
    getDarkMode: (state) => (key) => {
      var c = state.theme[key].substring(1)
      var rgb = parseInt(c, 16);   // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff;  // extract red
      var g = (rgb >> 8) & 0xff;  // extract green
      var b = (rgb >> 0) & 0xff;  // extract blue
      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      return luma < 180
    }
    
  }
})

export default store
