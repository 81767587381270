<template>
  <v-container fluid grid-list-xs class="pa-0">
    <hero/>
    <categories/>
    <featured-products/>
  </v-container>
</template>

<script>
import Hero from '@/components/Hero.vue'
import FeaturedProducts from '@/components/FeaturedProducts.vue'
import { mapState } from 'vuex'
import Categories from "@/components/Categories";

export default {
  components: {Categories, Hero, FeaturedProducts },
  computed: mapState(['store'])
}
</script>
