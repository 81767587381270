<template>
    <v-dialog v-model="dialog" max-width="1000">
        <template
            v-slot:activator="{ on }"
        >
            <v-btn icon v-on="on">
                <v-icon>mdi-store</v-icon>
            </v-btn>
        </template>
        <v-card flat>
            <nice-header>
                {{store.name}}
            </nice-header>
            <v-container fluid fill-height>
                <v-row>
                    <v-col cols="6">
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>mdi-map-marker</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="title">
                                    {{ store.name }}
                                </v-list-item-title>
                            </v-list-item>
                            <template v-for="(line, i) in address" id="storeAddress">
                                <v-list-item :key="i">
                                    <v-list-item-icon/>
                                    {{ line }}
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                    <v-col cols="6">
                        <h4 class="title mb-2">Opening hours</h4>
                        <v-simple-table dense>
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th class="text-center">Opening time</th>
                                    <th class="text-center">Closing time</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(day, x) in store.openingHours" :key="x">
                                  <td>{{day.day + 1 | toDay }}</td>
                                  <template v-if="day.open">
                                      <td class="text-center">{{day.openingTime | crop }}</td>
                                      <td class="text-center">{{day.closingTime | crop }}</td>
                                  </template>
                                  <td v-else colspan="2" class="text-center grey--text">CLOSED</td>
                              </tr>
                            </tbody>

                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions
                v-if="store.legalDocuments && store.legalDocuments.length > 0"
            >
                <v-spacer></v-spacer>
                <v-btn flat text color="primary" @click="jumpToLegal">
                    View terms and conditions for this store
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    import moment from 'moment'

    export default {
        name: 'StoreDetails',
        props: ['store'],
        data: () => ({
            dialog: false
        }),
        filters: {
            toDay (value) {
                return moment().day(value).format("dddd")
            },
            crop (value) {
                return value.slice(0,5)
            }
        },
        computed: {
            address () {
                if (this.store && this.store.address) {
                    return this.store.address.split('\n')
                }
                return []
            }
        },
        methods: {
            jumpToLegal () {
                this.dialog = false;
                this.$router.push(`/${this.store.hashId}/legal`)
            }
        }
    }
</script>
