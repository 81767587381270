<template>
  <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="auto" class="white" style="max-width: 40%">
          <h1 :class="`display-${$vuetify.breakpoint.mdAndUp ? '4' : '2'} font-weight-thin text-center black--text`">
            {{ store.name }}
          </h1>
          <h4 class="subheading black--text text-center mt-4" v-show="$vuetify.breakpoint.smAndUp" >
            {{ store.description }}
          </h4>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['store']
}
</script>