import AppBar from '@/components/AppBar';
import ProductCard from '@/components/ProductCard';
import Filters from '@/components/Filters';
import DeleteLineItem from '@/components/DeleteLineItem';
import PlaceOrder from '@/components/PlaceOrder';
import NiceHeader from '@/components/NiceHeader';
import ListItem from '@/components/ListItem';
import OrderLogin from '@/components/OrderLogin';
import ManualCheckout from '@/components/ManualCheckout';
import StoreDetails from '@/components/StoreDetails';
import ReviewDialog from '@/components/ReviewDialog';
import RatingsDialog from '@/components/RatingsDialog';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import DateTimePicker from '@/components/DateTimePicker';
import TextField from '@/components/TextField';
import TextArea from '@/components/TextArea';
import RSelect from '@/components/RSelect';
import Btn from '@/components/Btn'
import AddressField from '@/components/AddressField'

const GlobalComponents = {
  install (Vue) {
      Vue.component(AppBar.name, AppBar);
      Vue.component(Filters.name, Filters);
      Vue.component(ProductCard.name, ProductCard);
      Vue.component(PlaceOrder.name, PlaceOrder);
      Vue.component(DeleteLineItem.name, DeleteLineItem);
      Vue.component(NiceHeader.name, NiceHeader);
      Vue.component(ListItem.name, ListItem);
      Vue.component(OrderLogin.name, OrderLogin);
      Vue.component(ManualCheckout.name, ManualCheckout);
      Vue.component(StoreDetails.name, StoreDetails);
      Vue.component(ReviewDialog.name, ReviewDialog);
      Vue.component(RatingsDialog.name, RatingsDialog);
      Vue.component(DatePicker.name, DatePicker);
      Vue.component(TimePicker.name, TimePicker);
      Vue.component(DateTimePicker.name, DateTimePicker);
      Vue.component(TextField.name, TextField);
      Vue.component(TextArea.name, TextArea);
      Vue.component(RSelect.name, RSelect);
      Vue.component(Btn.name, Btn);
      Vue.component(AddressField.name, AddressField);
  }
};

export default GlobalComponents
