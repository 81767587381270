<template>
  <div>
  <v-text-field
    :outlined="inputStyles.outlined"
    :dense="inputStyles.dense"
    :solo="inputStyles.solo"
    :filled="inputStyles.filled"
    :rounded="inputStyles.rounded"
    :label="label"
    v-model="model"
    :rules="rules"
    :hide-details="hideDetails"
    v-bind="{ ...$props, ...$attrs }"
    :readonly="readonly"
    :disabled="disabled"
    :placeholder="placeholder"
    :append-icon="appendIcon"
    :prepend-inner-icon="prependInnerIcon"
    :error-messages="errorMessages"
    v-on="$listeners"
  >

  </v-text-field>
    <div v-if="errorMessages" class="error--text">{{errorMessages}}</div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TextField',
  props: ['value', "rules", "label", "hideDetails", 'readonly', 'disabled', 'placeholder', 'appendIcon', 'prependInnerIcon', 'on', 'errorMessages', 'clearable'],
  computed: {
    ...mapState(['inputStyles']),
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>