<template>
  <v-textarea
    auto-grow
    :outlined="inputStyles.outlined"
    :dense="inputStyles.dense"
    :solo="inputStyles.solo"
    :filled="inputStyles.filled"
    :rounded="inputStyles.rounded"
    :label="label"
    v-model="model"
    :rules="rules"
  >
  </v-textarea>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TextArea',
  props: ['value', "rules", "label"],
  computed: {
    ...mapState(['inputStyles']),
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>