<template>
    <v-dialog v-model="dialog" width="600">
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon>fa-user-circle</v-icon>
            </v-btn>
        </template>
        <v-card flat>
            
            <nice-header>Enter order details</nice-header>
            <v-card-text>
                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12">
                            <text-field
                                label="Email address"
                                v-model="form.email"
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col cols="12">
                            <text-field
                                label="Order number"
                                v-model="form.order"
                                :rules="[rules.required]"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <btn
                    color="accent"
                    class="no-transform"
                    :loading="loading"
                    :disabled="!valid"
                    @click="orderLogin"
                >View order</btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {orderLogin} from '@/graphql'
    import { mapState } from 'vuex'

    export default {
        name: 'OrderLogin',
        data: () => ({
            dialog: false,
            valid: null,
            loading: false,
            form: {
                email: null,
                order: null
            },
            rules: {
                required: v => !!v || 'This field is required'
            },
            error: false,
            errorMessage: null,
        }),
        computed: mapState(['store']),
        methods: {
            orderLogin () {
                this.loading = false;
                this.$apollo.mutate({
                    mutation: orderLogin,
                    variables: this.form
                }).then(({ data }) => {
                    const { token } = data.orderLogin;
                    this.loading = false;
                    this.dialog = false;
                    this.$router.push(`/orders/${token}`)
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
.no-transform {
  font-size: 16px;
  text-transform: none !important;
}
</style>