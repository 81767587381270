<template>
  <v-app-bar
      height="80px"
      v-if="store"
      flat
      :dark="theme.darkMode"
      :color="theme.darkMode ? null : 'white'"
      class="px-lg-16"
  >
    <v-toolbar-title class="font-weight-thin display-1" @click="$router.push('/')" style="cursor: pointer" v-text="store.name"/>
    <v-spacer/>
    <router-link
        v-show="$vuetify.breakpoint.mdAndUp"
        v-for="({ to, title }, i) in links"
        :to="to"
        style="text-decoration: none;"
        :class="$route.path === to ? 'accent--text px-3' : 'black--text px-3'"
        :key="i"
        v-html="title"
    />
    <v-spacer/>
    <search-dialog/>
    <order-login/>
    <v-btn icon link to="/basket">
      <v-badge :content="basket.lineItems.length" overlap :value="basket.lineItems.length">
        <v-icon>fa-shopping-basket</v-icon>
      </v-badge>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {products, basket} from '@/graphql'
import {mapState, mapGetters} from 'vuex'
import SearchDialog from "@/components/modals/SearchDialog";

export default {
  name: 'AppBar',
  components: {SearchDialog},
  props: ['theme'],
  methods: {
    selectProduct(productId) {
      this.$refs.search.blur()
      this.$apollo.queries.products.refresh()
      this.$router.push(`/product/${productId}`)
    }
  },
  apollo: {
    basket,
    products: {
      query: products,
      variables() {
        return {
          search: this.search
        }
      },
      debounce: 100
    }
  },
  computed: {
    ...mapState(['store']),
    ...mapGetters(['getDarkMode']),
    searchResults() {
      if (this.products && this.search) {
        return this.products.map(product => ({
          value: product.id,
          text: product.name,
          category: product.category.name,
          image: product.image
        }))
      }
      return []
    }
  },
  data: () => ({
    storeDetailsDialog: false,
    search: '',
    links: [
      { to: '/', title: 'Home' },
      { to: '/products', title: 'Products' },
      { to: '/about', title: 'About' },
      { to: '/contact', title: 'Contact us'}
    ]
  })
}
</script>