<template>
  <v-dialog v-model="dialog" width="600" :fullscreen="$vuetify.breakpoint.xsOnly">

    <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <btn :disabled="disabled" v-on="on" color="accent" class="text-center no-transform">
        Place order
      </btn>
    </template>
    <v-card>
      <v-card-title class="primary-title justify-center mb-2">
        Enter your details
        <v-spacer></v-spacer>
        <v-btn v-if="$vuetify.breakpoint.xsOnly" icon @click="dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12">
              <text-field
                  label="Name"
                  v-model="form.name"
                  :rules="[rules.required]"
              >
              </text-field>
            </v-col>
            <v-col cols="12" md="6">
              <text-field
                  label="Email"
                  :rules="[rules.required, rules.email]"
                  v-model="form.email"
              ></text-field>
            </v-col>
            <v-col cols="12" md="6">
              <text-field
                  label="Phone number"
                  :rules="[rules.required]"
                  v-model="form.phoneNumber"
              >
              </text-field>
            </v-col>

            <v-col cols="12" v-if="basket.lineItems[0].hireType.toUpperCase() === 'DELIVERY'">
              <address-field
                  v-model="form.deliveryAddress"
                  :store="store"
                  label="Your address"
                  :disabled="!!basket.deliveryAddress"
                  :product="basket.lineItems[0].assets[0].product"
                  :required="true"
              />
            </v-col>
            <v-col cols="12">
              <div>
                <v-checkbox v-model="form.accept" :rules="[rules.required]">
                  <template v-slot:label>
                    I accept the {{ store.name }} terms of service
                  </template>
                  <template v-slot:append>
                    <v-btn icon link to="/legal">
                      <v-icon>mdi-launch</v-icon>
                    </v-btn>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>


        <btn
            color="accent"
            class="no-transform"
            :disabled="!valid"
            :loading="loading"
            @click="goToStripe"
            v-if="store.billingEnabled && total > 0"
        >Buy now
        </btn>
        <btn
            v-else
            color="accent"
            class="no-transform"
            :disabled="!valid"
            :loading="loading"
            @click="placeOrder"
        >Place order
        </btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import {placeOrder, getCheckout} from '@/graphql'
import Cookie from 'js-cookie'
import {mapState} from 'vuex'

export default {
  name: 'PlaceOrder',
  props: ['basket', 'disabled'],
  data: () => ({
    valid: null,
    dialog: null,
    form: {
      name: null,
      email: null,
      phoneNumber: null,
      accept: false,
      deliveryAddress: null,
    },
    error: false,
    errorMessage: null,
    loading: false,
    rules: {
      required: v => !!v || 'This field is required',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    },
    checkoutSession: null,
    card: null,
    stripe: null,
    cardDialog: false
  }),
  computed: {
    ...mapState(['store']),
    total() {
      const {lineItems} = this.basket
      return lineItems.map(li => li.subtotal).reduce((a, b) => a + b)
    }
  },
  mounted () {
    this.form.deliveryAddress = this.basket.deliveryAddress
  },
  methods: {
    goToStripe() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: getCheckout,
        variables: {
          basket: Cookie.get('basket'),
          ...this.form,
        }
      }).then(({data}) => {
        const {publishableKey, sessionId, stripeAccount} = data.getCheckout;
        // eslint-disable-next-line
        var stripe = Stripe(publishableKey, {
          stripeAccount
        });
        stripe.redirectToCheckout({
          sessionId
        })
      }).catch((error) => {
        this.error = true;
        this.errorMessage = error.message;
        this.loading = false
      })
    },
    placeOrder() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: placeOrder,
        variables: {
          store: this.$store.hashId,
          basket: Cookie.get('basket'),
          hireType: this.basket.lineItems[0].hireType,
          ...this.form
        }
      }).catch((error) => {
        this.error = true;
        this.errorMessage = error.message;
        this.loading = false
      }).then(({data}) => {
        Cookie.remove('basket');
        const {accessToken} = data.placeOrder.order;
        const subdomain = location.host.split('.')[0]
        const invalidHosts = ['localhost:8081', 'store']
        if (invalidHosts.indexOf(subdomain) > -1) {
          this.$router.push(`/${this.$store.hashId}/orders/${accessToken}`);
        } else {
          this.$router.push(`/orders/${accessToken}`);
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.no-transform {
  text-transform: none;
  font-size: 16px;
}
</style>