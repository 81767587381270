<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="slotProps">
      <slot name="activator" v-bind="slotProps">
        <text-field
            :disabled="disabled"
            v-on="slotProps.on"
            :readonly="true"

            :value="value && value.text ? value.text : value"
            :label="label || 'Store Address'"
            autocomplete="new-password"
        />
      </slot>
    </template>
    <v-card flat>
      <nice-header>Address lookup</nice-header>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="handleChange">
          <vuetify-google-autocomplete
              id="map"
              rounded
              outlined
              :error-messages="errorMessages"
              :rules="rules"
              append-icon="mdi-magnify"
              clearable
              required
              v-bind:disabled="disabled"
              placeholder="Start typing"
              @change="handleChange"
              v-on:placechanged="selectAddress"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <btn color="primary" @click="$emit('save')" :disabled="!valid || !value">Continue</btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'

export default {
  components: {VuetifyGoogleAutocomplete},
  name: 'AddressField',
  props: ['value', 'label', 'disabled', 'store', 'errorMessages', 'rules'],
  data: () => ({
    dialog: false,
    valid: null,
  }),
  methods: {
    handleChange(value) {
      if (!value) {
        this.$emit('input', null)
      }
    },
    selectAddress(address) {
      const {
        name,
        locality,
        postal_code,
        latitude,
        longitude,
        administrative_area_level_1,
        country,
        administrative_area_level_2
      } = address
      const addressParts = [name, locality, postal_code, administrative_area_level_1, administrative_area_level_2, country]
      const formattedAddress = addressParts.filter(value => !!value).join(', ')
      this.$emit('input', {
        text: formattedAddress,
        value: [latitude, longitude]
      })
    }
  }
}
</script>