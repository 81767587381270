<template>
  <Modal width="auto" v-model="dialog">
    <template v-slot:button="{ on }">
      <v-btn icon v-on="on">
        <v-icon>fa-search</v-icon>
      </v-btn>
    </template>
    <template v-slot:header="{ title }">
      <v-container fluid grid-list-xs>
        <v-row :class="$vuetify.breakpoint.mdAndUp ? 'py-8 px-5' : 'pr-8'">
          <v-col cols="12" md="4">
            <r-select
              label="Select category"
              :items="formattedCategories"
              v-model="category"
            ></r-select>
          </v-col>
          <v-col cols="12" md="8">
            <v-form ref="form">
              <text-field
                :loading="$apollo.queries.productSearch.loading"
                label="Search"
                append-icon="fa-search"
                v-model="search"
              ></text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
      <v-card-text class="pa-10" style="min-height: 436px">
        <v-row align="center" justify="center" v-if="productSearch && productSearch.length > 0">
          <v-col cols="12">
            <div class="text-center display-1">Search results</div>
          </v-col>
          <search-result @select="navigate(product.id)" v-for="(product, i) in productSearch" :product="product" :key="i"/>
        </v-row>
        <div v-else-if="search && !$apollo.queries.productSearch.loading" class="text-center">
          <div>
            <div>No results found</div>
            <a @click="gotoProducts">See all products</a>
          </div>
        </div>
        <div v-else class="text-center">Use the above box to search</div>
      </v-card-text>
    </template>
  </Modal>
</template>

<script>
import { categories, productSearch } from "@/graphql";
import Modal from '@/components/modals/Modal.vue'
import SearchResult from "@/components/cards/SearchResult";
export default {
  components: { Modal, SearchResult },
  apollo: {
    categories,
    productSearch: {
      query: productSearch,
      variables () {
        const { search, category } = this;
        return { search, category }
      },
      debounce: 200,
      skip () {
        return !this.search || this.search.length < 4
      }
    }
  },
  methods: {
    navigate (productId) {
      this.$router.push(`/product/${productId}`)
      this.dialog = false
      this.$refs.form.reset()
      this.$apollo.queries.productSearch.refetch()
    },
    gotoProducts () {
      this.$router.push('/products')
      this.dialog = false
    }
  },
  data: () => ({
    dialog: false,
    search: null,
    category: 'Show all'
  }),
  computed: {
    formattedCategories () {
      if (this.categories) {
        return ['Show all', ...this.categories]
      }
      return []
    }
  }
}
</script>