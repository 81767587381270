<template>
  <v-carousel
      cycle
      hide-delimiters
      :show-arrows="false"
      hide-delimiter-background
      v-if="store && store.images.length > 0"
      :height="$vuetify.breakpoint.smAndDown ? '100%' : ''"
      :class="$vuetify.breakpoint.smAndDown ? 'pb-8' : ''"
  >
    <v-carousel-item
        v-for="(image, i) in store.images"
        :key="i"
    >
      <v-img contain :src="image.url">
        <slot>
          <inner-hero :store="store"/>
        </slot>
      </v-img>
    </v-carousel-item>
  </v-carousel>
  <v-carousel
      v-else
      hide-delimiters
      :show-arrows="false"
      hide-delimiter-background
  >
    <v-carousel-item>
      <v-img contain src="https://dummyimage.com/1424x400/4242424/969696">
        <slot name="inner">
          <inner-hero :store="store"/>
        </slot>
      </v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import {mapState} from 'vuex'
import InnerHero from "@/components/InnerHero";

export default {
  components: {InnerHero},
  computed: mapState(['store'])
}
</script>
