<template>
    <v-app style="width: 100vw">
        <v-main v-if="store && basket">
            <top-bar/>
            <app-bar :theme="theme"/>
            <router-view id="main" :search="search"/>
        </v-main>
        <Footer :store="store" class="mt-12"/>
        <v-footer app v-if="store && !store.active">
            <div>
            This store was created with <a href="https://rentworks.app" target="_blank" style="text-decoration: none"> Rentworks</a>
            </div>
        </v-footer>
    </v-app>
</template>

<script>
    import {store, theme, basket, sessionId} from '@/graphql'
    import Cookie from 'js-cookie'
    import TopBar from '@/components/TopBar.vue'
    import Footer from '@/components/Footer'
    import moment from 'moment'

    export default {
        name: 'App',
        components: { TopBar, Footer },
        metaInfo () {
          if (this.store) {
            const title = this.store.active ? this.store.name : `${this.store.name} | Rentworks`
            return {
              title
            }
          }
          return { title: 'Rentworks' }
        },
        apollo: {
            store,
            theme,
            sessionId,
            basket
        },
        methods: {
            updateBasket() {
                this.$apollo.queries.basket.refetch()
            },
        },
        data: () => ({
            search: null
        }),
        watch: {
            theme (theme) {
                const { 
                    primary,
                    secondary,
                    accent,
                    info,
                    warning,
                    success,
                    error,
                } = theme
                if (theme.darkMode) {
                    this.$vuetify.theme.dark = true
                    this.$vuetify.theme.themes.dark = { 
                        primary,
                        secondary,
                        accent,
                        info,
                        warning,
                        success,
                        error,
                    }
                } else {
                    this.$vuetify.theme.themes.light = { 
                        primary,
                        secondary,
                        accent,
                        info,
                        warning,
                        success,
                        error,
                    }
                }
                this.$store.commit('setTheme', { 
                    primary,
                    secondary,
                    accent,
                    info,
                    warning,
                    success,
                    error,
                })
                const {
                    solo,
                    filled,
                    outlined,
                    dense,
                    rounded,
                } = theme
                this.$store.commit('setInputStles', {
                    solo,
                    filled,
                    outlined,
                    dense,
                    rounded,
                })
            },
            store (store) {
                this.$store.commit('setStore', { store })
            },
            basket (basket) {
                if (!Cookie.get('basket')){
                  Cookie.set('basket', basket.identifier)
                }
                if (basket.lineItems.length > 0) {
                    const start = moment(basket.lineItems[0].startTime).utc(false)

                    const date = start.format('YYYY-MM-DD')
                    const time = start.format('kk:mm')
                    this.$store.commit('setStartDatetime', { date, time })

                    const end = moment(basket.lineItems[0].endTime).utc(false)
                    const endDate = end.format('YYYY-MM-DD')
                    const endTime = end.format('kk:mm')
                    this.$store.commit('setEndDatetime', { date: endDate, time: endTime })
                }
            }
        }
    };
</script>

<style>
.no-transform {
  font-size: 16px;
  text-transform: none !important;
}
</style>