<template>
  <v-select
    :outlined="inputStyles.outlined"
    :dense="inputStyles.dense"
    :solo="inputStyles.solo"
    :filled="inputStyles.filled"
    :items="items"
    :rounded="inputStyles.rounded"
    :label="label"
    v-model="model"
    :rules="rules"
    :hide-details="hideDetails"
    v-bind="{ ...$props, ...$attrs }"
    :readonly="readonly"
    :disabled="disabled"
    :placeholder="placeholder"
    :append-icon="appendIcon"
    :prepend-inner-icon="prependInnerIcon"
    @click:append="$emit('click:append')"
    @click:prepend-inner="$emit('click:prepend-inner')"
  >
  </v-select>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'RSelect',
  props: ['value', "rules", "label", "hideDetails", 'readonly', 'disabled', 'placeholder', 'appendIcon', 'prependInnerIcon', 'items', 'itemText'],
  computed: {
    ...mapState(['inputStyles']),
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>