<template>
    <div>
        <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
        <btn
            outlined
            color="accent"
            class="no-transform"
            :loading="loading"
            @click="checkout"
        >
            Pay now
        </btn>
    </div>
</template>

<script>
    import {getManualOrderCheckout} from '@/graphql'
    export default {
        name: 'ManualCheckout',
        props: ['order'],
        data: () => ({
            loading: false,
            error: false,
            errorMessage: null
        }),
        methods: {
            checkout () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: getManualOrderCheckout,
                    variables: {
                        orderNumber: this.order.hashId,
                        urlHash: this.$route.params.accessToken
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then(({ data }) => {
                    const { publishableKey, sessionId, stripeAccount } = data.getManualOrderCheckout;
                    // eslint-disable-next-line
                    var stripe = Stripe(publishableKey, {
                        stripeAccount
                    });
                    stripe.redirectToCheckout({
                        sessionId
                    })
                })
            }
        }
    }
</script>

<style scoped>
.no-transform {
  font-size: 16px;
  text-transform: none !important;
}
</style>