<template>
  <v-container class="my-10">
    <v-row justify="center" align="center">
      <v-col cols="12"><h2 class="display-3 font-weight-thin text-center">Featured products</h2></v-col>
      <v-col cols="12">
        <v-container fluid>
          <v-data-iterator
            :items="featuredProducts"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            :sort-by="sortBy.toLowerCase()"
            :sort-desc="sortDesc"
            hide-default-footer
          >
            <template v-slot:default="{ items }">
              <v-row justify="center" align="center">
                  <search-result
                      @select="navigate(item.id)"
                      :product="item"
                      :key="`card-${item.id}`"
                      v-for="item in items"
                  />
               </v-row>
            </template>

            <template v-slot:footer>
              <v-row
                class="mt-2"
                align="center"
                justify="center"
              >
                <v-spacer></v-spacer>
                <btn color="accent" class="no-transform" link to="/products">See all products</btn>
                <v-spacer></v-spacer>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import {products} from '@/graphql'
  import SearchResult from "@/components/cards/SearchResult";
  
  export default {
    apollo: { products },
    components: { SearchResult },
    mounted () {
      var self = this;
      setInterval (() => {
        self.nextPage()
      }, 12000)
    },
    data () {
      return {
        itemsPerPageArray: [3, 6, 9],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 10,
        sortBy: 'name',
      }
    },
    computed: {
      featuredProducts () {
        if (this.products) {
          return this.products.filter(prod => prod.featured)  
        }
        return []
      },
      numberOfPages () {
        if (this.featuredProducts) {
          return Math.ceil(this.featuredProducts.length / this.itemsPerPage)
        }
        return 0
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
    },
    methods: {
      navigate (productId) {
        this.$router.push(`/product/${productId}`)
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) {
          this.page += 1
        } else {
          this.page = 1
        }
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    },
  }
</script>
<style scoped>
.no-transform {
  font-size: 16px;
  text-transform: none !important;
}
</style> 