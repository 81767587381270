<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        :disabled="readonly"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="date"
            dense
            solo
            flat
            hide-details
            readonly
            :disabled="readonly"
            placeholder="YYYY-MM-DD"
            v-bind="{ ...$attrs, ...$props }"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :min="min"
          :readonly="readonly"
          :allowed-dates="allowedDates"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
</template>


<script>
    export default {
        name: 'DatePicker',
        props: ['value', 'label', 'rules', 'min', 'icon', 'allowedDates', 'readonly'],
        data: () => ({
            menu: null,
        }),
        computed: {
            date: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>


<style>
.v-input__slot {
  padding-right:0 
}
</style>